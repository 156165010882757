import "./App.css";
import { useEffect, useState } from "react";

const HOST = process.env.NODE_ENV === "dev"
    ? "http://127.0.0.1:8787"
    : "https://john-law.poyea.workers.dev";

const App = () => {
    const [content, setContent] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [postUserName, setPostUserName] = useState("");
    const [postUserId, setPostUserId] = useState("");
    const [postTitle, setPostTitle] = useState("");
    const [postMessage, setPostMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${HOST}/posts`);
            if (response.ok) {
                const text = await response.text();
                setContent(JSON.parse("[" + text + "]"));
                setLoaded(true);
            }
        };
        fetchData();
    }, []);

    const postData = async () => {
        setSubmitted(true);
        await fetch(`${HOST}/posts`, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
                user_name: postUserName,
                user_id: postUserId,
                post_title: postTitle,
                post_content: postMessage,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    if (loaded) {
        return (
            <div className="App">
                {content.map((entry, i) => (
                    <div className="postCard" key={i}>
                        <div className="postTitle">
                            <b>{entry["post_title"]}</b>
                        </div>
                        <div className="postUser">
                            Author: {entry["user_name"]}#{entry["user_id"]}
                        </div>
                        <div className="postBody">{entry["post_content"]}</div>
                    </div>
                ))}
                <div className="postInput">
                    <input
                        placeholder="User Name"
                        onInput={(e) => setPostUserName(e.target.value)}
                    ></input>
                    <input
                        placeholder="User ID"
                        onInput={(e) => setPostUserId(e.target.value)}
                    ></input>
                    <input
                        placeholder="Post Title"
                        onInput={(e) => setPostTitle(e.target.value)}
                    ></input>
                    <textarea
                        rows="5"
                        placeholder="Message"
                        onInput={(e) => setPostMessage(e.target.value)}
                    ></textarea>
                    <button onClick={postData} disabled={submitted}>
                        Add Post
                    </button>
                </div>
            </div>
        );
    } else {
        return <div className="App">Loading...</div>;
    }
};

export default App;
